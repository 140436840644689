// Generated by Melange

import * as Curry from "melange.runtime/curry.bs.js";
import * as $$Promise from "../node_modules/reason-promise/src/js/promise.bs.js";
import * as Bs_fetch__Fetch from "../node_modules/bs-fetch/src/Fetch.bs.js";
import * as Melange__Let_syntax from "./let_syntax.bs.js";

function request_json(endpoint) {
  return Curry._2(Melange__Let_syntax.Bindings.let$plus, $$Promise.Js.toResult($$Promise.Js.fromBsPromise(fetch(endpoint, Curry._2(Bs_fetch__Fetch.RequestInit.make(undefined, [[
                                        "accept",
                                        "application/json"
                                      ]], undefined, undefined, undefined, /* CORS */3)(undefined, undefined, undefined, undefined, undefined), undefined, undefined)).then(function (prim) {
                          return prim.json();
                        }))), (function (response) {
                if (response.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: response._0
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: response._0.message
                        };
                }
              }));
}

export {
  request_json ,
}
/* Promise Not a pure module */
