// Generated by Melange

import * as Curry from "melange.runtime/curry.bs.js";
import * as React from "react";
import * as Stdlib from "melange/./stdlib.bs.js";
import * as Caml_option from "melange.runtime/caml_option.bs.js";

function dayOfWeek(param) {
  switch (param) {
    case 0 :
        return "Sunday";
    case 1 :
        return "Monday";
    case 2 :
        return "Tuesday";
    case 3 :
        return "Wednesday";
    case 4 :
        return "Thursday";
    case 5 :
        return "Friday";
    case 6 :
        return "Saturday";
    default:
      throw {
            RE_EXN_ID: "Assert_failure",
            _1: [
              "utils.ml",
              17,
              4
            ],
            Error: new Error()
          };
  }
}

function formatDate(d) {
  return dayOfWeek(d.getDay() | 0) + (", " + d.toLocaleString());
}

var QueryParams = {};

function throttle(f, ms) {
  var last = {
    contents: Stdlib.max_float
  };
  var timer = {
    contents: undefined
  };
  return function (arg) {
    var now = Date.now();
    if (now < last.contents + ms) {
      var id = timer.contents;
      if (id !== undefined) {
        clearTimeout(Caml_option.valFromOption(id));
      }
      timer.contents = Caml_option.some(setTimeout((function (param) {
                  last.contents = Date.now();
                  Curry._1(f, arg);
                }), ms));
      return ;
    }
    last.contents = Date.now();
    Curry._1(f, arg);
  };
}

function useScroll(param) {
  var match = React.useState(function () {
        return {
                x: window.pageXOffset,
                y: window.pageYOffset
              };
      });
  var updateState = match[1];
  var handler = throttle((function (param) {
          Curry._1(updateState, (function (param) {
                  return {
                          x: window.pageXOffset,
                          y: window.pageYOffset
                        };
                }));
        }), 200);
  React.useEffect((function () {
          window.addEventListener("scroll", handler);
          return (function (param) {
                    window.removeEventListener("scroll", handler);
                  });
        }), []);
  return match[0];
}

var Scroll = {
  useScroll: useScroll
};

export {
  dayOfWeek ,
  formatDate ,
  QueryParams ,
  throttle ,
  Scroll ,
}
/* react Not a pure module */
