// Generated by Melange

import * as $$Promise from "../node_modules/reason-promise/src/js/promise.bs.js";
import * as Belt_Option from "melange.belt/./belt_Option.bs.js";
import * as Belt_Result from "melange.belt/./belt_Result.bs.js";
import * as Caml_option from "melange.runtime/caml_option.bs.js";

var and$star = $$Promise.all2;

var Async = {
  let$plus: $$Promise.map,
  let$star: $$Promise.flatMap,
  and$star: and$star
};

function and$star$1(o1, o2) {
  if (o1 !== undefined && o2 !== undefined) {
    return [
            Caml_option.valFromOption(o1),
            Caml_option.valFromOption(o2)
          ];
  }
  
}

var $$Option = {
  let$plus: Belt_Option.map,
  let$star: Belt_Option.flatMap,
  and$star: and$star$1
};

function and$star$2(r1, r2) {
  if (r1.TAG === /* Ok */0) {
    if (r2.TAG === /* Ok */0) {
      return {
              TAG: /* Ok */0,
              _0: [
                r1._0,
                r2._0
              ]
            };
    } else {
      return {
              TAG: /* Error */1,
              _0: r2._0
            };
    }
  } else {
    return {
            TAG: /* Error */1,
            _0: r1._0
          };
  }
}

var Result = {
  let$plus: Belt_Result.map,
  let$star: Belt_Result.flatMap,
  and$star: and$star$2
};

function and$star$star$bang(lr1, lr2) {
  return $$Promise.map($$Promise.all2(lr1, lr2), (function (param) {
                var x = param[0];
                if (x.TAG !== /* Ok */0) {
                  return {
                          TAG: /* Error */1,
                          _0: x._0
                        };
                }
                var y = param[1];
                if (y.TAG === /* Ok */0) {
                  return {
                          TAG: /* Ok */0,
                          _0: [
                            x._0,
                            y._0
                          ]
                        };
                } else {
                  return {
                          TAG: /* Error */1,
                          _0: y._0
                        };
                }
              }));
}

var Bindings = {
  let$plus: $$Promise.map,
  let$star: $$Promise.flatMap,
  and$star: and$star,
  let$star$question: Belt_Option.flatMap,
  let$plus$question: Belt_Option.map,
  and$star$question: and$star$1,
  let$star$bang: Belt_Result.flatMap,
  let$plus$bang: Belt_Result.map,
  and$star$bang: and$star$2,
  let$star$star$bang: $$Promise.flatMapOk,
  let$plus$plus$bang: $$Promise.mapOk,
  and$star$star$bang: and$star$star$bang
};

export {
  Async ,
  $$Option ,
  Result ,
  Bindings ,
}
/* Promise Not a pure module */
