// Generated by Melange

import * as Curry from "melange.runtime/curry.bs.js";
import * as Decoders_bs from "../node_modules/bs-decoders/src-bs/decoders_bs.bs.js";

var x = Decoders_bs.Decode.$great$great$eq;

var decode_author = Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._2(Decoders_bs.Decode.field, "name", Decoders_bs.Decode.string), (function (name) {
        return Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._1(Decoders_bs.Decode.maybe, Curry._2(Decoders_bs.Decode.field, "uri", Decoders_bs.Decode.string)), (function (uri) {
                      return Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._1(Decoders_bs.Decode.maybe, Curry._2(Decoders_bs.Decode.field, "email", Decoders_bs.Decode.string)), (function (email) {
                                    return Curry._1(Decoders_bs.Decode.succeed, {
                                                name: name,
                                                uri: uri,
                                                email: email
                                              });
                                  }));
                    }));
      }));

var decode_link = Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._2(Decoders_bs.Decode.field, "title", Decoders_bs.Decode.string), (function (title) {
        return Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._2(Decoders_bs.Decode.field, "href", Decoders_bs.Decode.string), (function (href) {
                      return Curry._1(Decoders_bs.Decode.succeed, {
                                  title: title,
                                  href: href
                                });
                    }));
      }));

var decode_authors_field = Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._2(Decoders_bs.Decode.index, 0, decode_author), (function (author) {
        return Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._2(Decoders_bs.Decode.index, 1, Curry._1(Decoders_bs.Decode.list, decode_author)), (function (authors) {
                      return Curry._1(Decoders_bs.Decode.succeed, [
                                  author,
                                  authors
                                ]);
                    }));
      }));

var decode_entry = Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._2(Decoders_bs.Decode.field, "authors", decode_authors_field), (function (authors) {
        return Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._1(Decoders_bs.Decode.maybe, Curry._2(Decoders_bs.Decode.field, "content", Decoders_bs.Decode.string)), (function (content) {
                      return Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._2(Decoders_bs.Decode.field, "id", Decoders_bs.Decode.string), (function (id) {
                                    return Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._2(Decoders_bs.Decode.field, "links", Curry._1(Decoders_bs.Decode.list, decode_link)), (function (links) {
                                                  return Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._1(Decoders_bs.Decode.maybe, Curry._2(Decoders_bs.Decode.field, "published", Decoders_bs.Decode.$$float)), (function (published) {
                                                                return Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._2(Decoders_bs.Decode.field, "title", Decoders_bs.Decode.string), (function (title) {
                                                                              return Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._2(Decoders_bs.Decode.field, "updated", Decoders_bs.Decode.$$float), (function (updated) {
                                                                                            return Curry._1(Decoders_bs.Decode.succeed, {
                                                                                                        authors: authors,
                                                                                                        content: content,
                                                                                                        id: id,
                                                                                                        links: links,
                                                                                                        published: published,
                                                                                                        title: title,
                                                                                                        updated: updated
                                                                                                      });
                                                                                          }));
                                                                            }));
                                                              }));
                                                }));
                                  }));
                    }));
      }));

var decode_feed = Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._2(Decoders_bs.Decode.field, "authors", Curry._1(Decoders_bs.Decode.list, decode_author)), (function (authors) {
        return Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._2(Decoders_bs.Decode.field, "id", Decoders_bs.Decode.string), (function (id) {
                      return Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._2(Decoders_bs.Decode.field, "links", Curry._1(Decoders_bs.Decode.list, decode_link)), (function (links) {
                                    return Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._1(Decoders_bs.Decode.maybe, Curry._2(Decoders_bs.Decode.field, "logo", Decoders_bs.Decode.string)), (function (logo) {
                                                  return Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._1(Decoders_bs.Decode.maybe, Curry._2(Decoders_bs.Decode.field, "subtitle", Decoders_bs.Decode.string)), (function (subtitle) {
                                                                return Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._2(Decoders_bs.Decode.field, "title", Decoders_bs.Decode.string), (function (title) {
                                                                              return Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._2(Decoders_bs.Decode.field, "updated", Decoders_bs.Decode.$$float), (function (updated) {
                                                                                            return Curry._2(Decoders_bs.Decode.Infix.$great$great$eq, Curry._2(Decoders_bs.Decode.field, "entries", Curry._1(Decoders_bs.Decode.list, decode_entry)), (function (entries) {
                                                                                                          return Curry._1(Decoders_bs.Decode.succeed, {
                                                                                                                      authors: authors,
                                                                                                                      id: id,
                                                                                                                      links: links,
                                                                                                                      logo: logo,
                                                                                                                      subtitle: subtitle,
                                                                                                                      title: title,
                                                                                                                      updated: updated,
                                                                                                                      entries: entries
                                                                                                                    });
                                                                                                        }));
                                                                                          }));
                                                                            }));
                                                              }));
                                                }));
                                  }));
                    }));
      }));

export {
  x ,
  decode_author ,
  decode_link ,
  decode_entry ,
  decode_feed ,
}
/* decode_author Not a pure module */
