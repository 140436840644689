// Generated by Melange

import Swr from "swr";
import * as Swr$1 from "swr";
import * as Curry from "melange.runtime/curry.bs.js";
import * as React from "react";
import * as Belt_List from "melange.belt/./belt_List.bs.js";
import * as Belt_Array from "melange.belt/./belt_Array.bs.js";
import * as Caml_option from "melange.runtime/caml_option.bs.js";
import * as Melange__Ui from "./ui.bs.js";
import * as Melange__Api from "./api.bs.js";
import * as Melange__Utils from "./utils.bs.js";
import * as Stdlib__Format from "melange/stdlib_modules/format.bs.js";
import * as AppModuleScss from "./App.module.scss";
import * as ReasonReactRouter from "../node_modules/reason-react/src/ReasonReactRouter.bs.js";

var css = AppModuleScss;

function App$Entry(Props) {
  var entry = Props.entry;
  var published = entry.published;
  var dateMs = published !== undefined ? published : entry.updated;
  var jsDate = new Date(dateMs);
  var date = Melange__Utils.formatDate(jsDate);
  var dateCaption = "On " + (date + ":");
  var match = entry.links;
  var tmp;
  if (match) {
    var match$1 = match.hd;
    tmp = React.createElement("a", {
          title: match$1.title,
          href: match$1.href
        }, dateCaption);
  } else {
    tmp = dateCaption;
  }
  var content = entry.content;
  return React.createElement("article", undefined, React.createElement(Melange__Ui.Card.make, {
                  children: null
                }, React.createElement("div", {
                      className: css.entryDate
                    }, tmp), content !== undefined ? React.createElement("div", {
                        className: css.entryContent,
                        dangerouslySetInnerHTML: {
                          __html: content
                        }
                      }) : null));
}

var Entry = {
  make: App$Entry
};

function feedTitle(link, feedTitle$1) {
  if (link !== undefined) {
    return React.createElement("a", {
                className: css.feedTitle,
                title: link.title,
                href: link.href
              }, feedTitle$1);
  } else {
    return React.createElement("span", {
                className: css.feedTitle
              }, feedTitle$1);
  }
}

function App$Header(Props) {
  var link = Props.link;
  var title = Props.title;
  return React.createElement("header", {
              className: css.appHeader
            }, React.createElement("h2", undefined, feedTitle(link, title)));
}

var Header = {
  feedTitle: feedTitle,
  make: App$Header
};

function App$FeedPage(Props) {
  var token = Props.token;
  var page = Props.page;
  var user = Props.user;
  var loadingRef = Props.loadingRef;
  var match = Swr(Melange__Api.feedEndpoint(token, page, user));
  var data = match.data;
  React.useEffect((function () {
          loadingRef.current = false;
        }), []);
  var tmp;
  if (data !== undefined && data.TAG === /* Ok */0) {
    var entries = data._0.entries;
    tmp = Belt_List.reduceWithIndexU(entries, new Array(Belt_List.length(entries)), (function (arr, entry, i) {
            arr[i] = React.createElement(App$Entry, {
                  entry: entry,
                  key: String(i)
                });
            return arr;
          }));
  } else {
    tmp = null;
  }
  return React.createElement("section", undefined, tmp);
}

var FeedPage = {
  make: App$FeedPage
};

function App$GithubFeed(Props) {
  var token = Props.token;
  var user = Props.user;
  var page = Props.page;
  var loadingRef = Props.loadingRef;
  var match = Swr(Melange__Api.feedEndpoint(token, 1, user));
  var data = match.data;
  var match$1;
  if (data !== undefined) {
    if (data.TAG === /* Ok */0) {
      var match$2 = data._0;
      var title = match$2.title;
      var links = match$2.links;
      match$1 = links ? [
          links.hd,
          title
        ] : [
          undefined,
          title
        ];
    } else {
      match$1 = [
        undefined,
        Curry._1(Stdlib__Format.asprintf(/* Format */{
                  _0: {
                    TAG: /* String_literal */11,
                    _0: "Error: ",
                    _1: {
                      TAG: /* String */2,
                      _0: /* No_padding */0,
                      _1: /* End_of_format */0
                    }
                  },
                  _1: "Error: %s"
                }), data._0)
      ];
    }
  } else {
    match$1 = [
      undefined,
      "ERROR TODO"
    ];
  }
  var tmp = {
    title: match$1[1]
  };
  var tmp$1 = match$1[0];
  if (tmp$1 !== undefined) {
    tmp.link = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(App$Header, tmp), React.createElement("main", {
                  className: css.appMain
                }, Belt_Array.makeByU(page, (function (i) {
                        var tmp = {
                          page: i + 1 | 0,
                          user: user,
                          loadingRef: loadingRef
                        };
                        if (token !== undefined) {
                          tmp.token = Caml_option.valFromOption(token);
                        }
                        return React.createElement(React.Suspense, {
                                    children: React.createElement(App$FeedPage, tmp),
                                    fallback: React.createElement("div", {
                                          className: css.loader
                                        }),
                                    key: i
                                  });
                      }))));
}

var GithubFeed = {
  make: App$GithubFeed
};

function App(Props) {
  var match = ReasonReactRouter.useUrl(undefined, undefined);
  var path = match.path;
  var match$1 = React.useState(function () {
        return 1;
      });
  var setPage = match$1[1];
  var page = match$1[0];
  var match$2 = Melange__Utils.Scroll.useScroll(undefined);
  var y = match$2.y;
  var loadingRef = React.useRef(false);
  React.useEffect((function () {
          var loading = loadingRef.current;
          if (!loading) {
            var totalHeight = window.innerHeight + y | 0;
            var offsetHeight = document.documentElement.offsetHeight;
            if (totalHeight === offsetHeight) {
              loadingRef.current = true;
              Curry._1(setPage, (function (prev) {
                      return prev + 1 | 0;
                    }));
            }
            
          }
          
        }), [
        page,
        setPage,
        y
      ]);
  var qp = new URLSearchParams(match.search);
  var user = path ? path.hd : "anmonteiro";
  var token = qp.get("token");
  var tmp = {
    user: user,
    page: page,
    loadingRef: loadingRef
  };
  var tmp$1 = (token == null) ? undefined : Caml_option.some(token);
  if (tmp$1 !== undefined) {
    tmp.token = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Swr$1.SWRConfig, {
              value: {
                fetcher: Melange__Api.fetcher,
                suspense: true
              },
              children: React.createElement("div", undefined, React.createElement(React.Suspense, {
                        children: React.createElement(App$GithubFeed, tmp),
                        fallback: React.createElement(App$Header, {
                              title: "Loading"
                            })
                      }))
            });
}

var make = App;

export {
  css ,
  Entry ,
  Header ,
  FeedPage ,
  GithubFeed ,
  make ,
}
/* css Not a pure module */
