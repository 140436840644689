// Generated by Melange

import * as React from "react";
import * as Stdlib from "melange/./stdlib.bs.js";
import * as ReactDom from "react-dom";
import * as IndexScss from "./index.scss";
import * as Melange__App from "./App.bs.js";
import * as ServiceWorker from "./serviceWorker";

var _css = IndexScss;

function register_service_worker(prim) {
  ServiceWorker.register();
}

function unregister_service_worker(prim) {
  ServiceWorker.unregister();
}

function renderToElementWithClassName(className) {
  var elements = document.getElementsByClassName(className);
  if (elements.length) {
    return ReactDom.createRoot(elements[0]);
  }
  throw {
        RE_EXN_ID: Stdlib.Invalid_argument,
        _1: "ReactDOMRe.Unstable.renderToElementWithClassName: no element of class " + (className + " found in the HTML."),
        Error: new Error()
      };
}

function createRootWithId(id) {
  var element = document.getElementById(id);
  if (!(element == null)) {
    return ReactDom.createRoot(element);
  }
  throw {
        RE_EXN_ID: Stdlib.Invalid_argument,
        _1: "ReactDOMRe.Unstable.createRootWithId: no element of id " + (id + " found in the HTML."),
        Error: new Error()
      };
}

var ReactDOM = {
  renderToElementWithClassName: renderToElementWithClassName,
  createRootWithId: createRootWithId
};

function start(param) {
  var root = createRootWithId("root");
  root.render(React.createElement(Melange__App.make, {}));
}

start(undefined);

ServiceWorker.unregister();

export {
  _css ,
  register_service_worker ,
  unregister_service_worker ,
  ReactDOM ,
  start ,
}
/* _css Not a pure module */
