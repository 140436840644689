// Generated by Melange

import * as React from "react";
import * as UiModuleScss from "./Ui.module.scss";

var css = UiModuleScss;

function Ui$Card(Props) {
  var children = Props.children;
  return React.createElement("div", {
              className: css.card
            }, React.createElement("div", {
                  className: css.cardInner
                }, children));
}

var Card = {
  make: Ui$Card
};

export {
  css ,
  Card ,
}
/* css Not a pure module */
