// Generated by Melange

import * as Curry from "melange.runtime/curry.bs.js";
import * as $$Promise from "../node_modules/reason-promise/src/js/promise.bs.js";
import * as Belt_Result from "melange.belt/./belt_Result.bs.js";
import * as Caml_option from "melange.runtime/caml_option.bs.js";
import * as Decoders_bs from "../node_modules/bs-decoders/src-bs/decoders_bs.bs.js";
import * as Stdlib__Format from "melange/stdlib_modules/format.bs.js";
import * as Melange__Request from "./request.bs.js";
import * as Melange__Decode_feed from "./decode_feed.bs.js";

function parseFeed(payload) {
  var data = Curry._2(Decoders_bs.Decode.decode_value, Melange__Decode_feed.decode_feed, payload);
  if (data.TAG === /* Ok */0) {
    return {
            TAG: /* Ok */0,
            _0: data._0
          };
  } else {
    return {
            TAG: /* Error */1,
            _0: Curry._2(Stdlib__Format.asprintf(/* Format */{
                      _0: {
                        TAG: /* Alpha */15,
                        _0: /* End_of_format */0
                      },
                      _1: "%a"
                    }), Decoders_bs.Decode.pp_error, data._0)
          };
  }
}

function feedEndpoint(token, page, user) {
  var endpoint = "/api?user=" + user + "&page=" + page;
  if (token !== undefined) {
    return "" + endpoint + "&token=" + Caml_option.valFromOption(token);
  } else {
    return endpoint;
  }
}

function fetcher(endpoint) {
  var p_result = Melange__Request.request_json(endpoint);
  return $$Promise.map(p_result, (function (r) {
                return Belt_Result.flatMap(r, parseFeed);
              }));
}

var SWRConfig = {};

var SWR = {
  SWRConfig: SWRConfig
};

export {
  parseFeed ,
  feedEndpoint ,
  fetcher ,
  SWR ,
}
/* Promise Not a pure module */
